@font-face {
  font-family: ComicSans;
  src: url(fonts/ComicSansMS3.ttf);
}

.fillParent {
  width: 100%;
  height: 100%;
}

.fillWithOption {
  width: 100%;
  height: 88%;
}

.topOption {
  width: 100%;
  height: 12%;
}

.halfPage {
  width: 50%;
}

.quarterPage {
  width: 100%;
  height: 50%;
}
.thirdPageWidth {
  width: calc(100% / 3);
}

.flex-container {
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-container > div {
  margin: 40px;
  letter-spacing: 15px;
  font-family: "ComicSans";
  font-size: 60px;
  text-align: left;
}

.flex-container > button {
  width: 100%;
  padding: calc(100% / 39);
  letter-spacing: 15px;
  font-family: "ComicSans";
  font-size: 60px;
}

.hideOverflow {
  overflow: hidden;
}

html,
body {
  margin: 0px;
  height: 100%;
}

p.login {
  text-align: center;
  position: relative;
  bottom: 19vh;
}

div.create-top {
  position: absolute;
  height: 100%;
  width: 100%;
}

div.create-bottom {
  position: absolute;
  top: 12%;
  height: 88%;
  width: 100%;
}

div.account-username {
  z-index: 1;
  position: absolute;
  letter-spacing: 10px;
  font-family: "ComicSans";
  font-size: 60px;
  padding: 30px;
}

.title {
  z-index: 1;
  position: absolute;
  top: 10%;
  width: 100%;
  text-align: center;
  letter-spacing: 10px;
  font-family: "ComicSans";
  font-size: 150px;
  color: #5d45c4;
  -webkit-text-stroke-width: 5px;
  -webkit-text-stroke-color: white;
  pointer-events: none;
}

button {
  display: inline;
  letter-spacing: 15px;
  font-family: "ComicSans";
  font-size: 60px;
  border: none;
}

.transition {
  transition-duration: 0.2s;
}
button.homePage {
  text-align: center;
}

.cursorText {
  cursor: text;
}

input[type="text"] {
  position: relative;
  bottom: 5vh;
  z-index: 2;
  padding: 20px 40px 20px 40px;
  border-style: solid;
  border-radius: 10vh;
  border-color: white;
  font-size: 30px;
}

input[type="password"] {
  position: relative;
  bottom: 5vh;
  z-index: 2;
  padding: 20px 40px 20px 40px;
  border-style: solid;
  border-radius: 10vh;
  border-color: white;
  font-size: 30px;
}

input[type="text"]:focus-visible {
  outline: none;
}

input[type="password"]:focus-visible {
  outline: none;
}

.leftHalf {
  position: absolute;

  width: 50%;
  height: 100%;
}

.rightHalf {
  position: absolute;
  left: 50%;

  width: 50%;
  height: 100%;
}

.leftSideDashedBorder {
  border-left: 3px solid #ff0000;
  /*TODO : MAKE BETTER, maybe use p5*/
}

.rightSideDashedBorder {
  border-right: 3px solid #ff0000;
  /*TODO : MAKE BETTER, maybe use p5*/
}

.topSideDashedBorder {
  border-top: 3px solid #ff0000;
  /*TODO : MAKE BETTER, maybe use p5*/
}

.bottomSideDashedBorder {
  border-bottom: 3px solid #ff0000;
  /*TODO : MAKE BETTER, maybe use p5*/
}

.defaultColor {
  background-color: #ef3629;
  color: white;
}

.defaultColor:hover {
  background-color: #d14d44;
  color: white;
}

.notInteractableColor {
  background-color: #0a022a;
  color: white;
  pointer-events: none;
}

.submittable {
  background-color: #0ed145;
  color: white;
}

.submittable:hover {
  background-color: #2bef63;
  color: white;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 3px;
  background-color: #ff0000;
}

.grid-container > div {
  text-align: center;
  letter-spacing: 15px;
  font-family: "ComicSans";
  font-size: 60px;
}

.statsBar1 {
  line-height: 150px;
  grid-column: 1 / 3;
}

.statsBar2 {
  line-height: 150px;
  grid-column: 3 / 5;
}

.playerSide {
  grid-area: 2 / 1 / 8 / 3;
}

.opponentSide {
  grid-area: 2 / 3 / 8 / 5;
}

.whatWillYouDo {
  line-height: 200px;
  grid-area: 8 / 1 / 10 / 3;
}

.rock {
  grid-area: 8 / 3 / 9 / 4;
}

.paper {
  grid-area: 8 / 4 / 9 / 5;
}

.scissors {
  grid-area: 9 / 3 / 10 / 4;
}

.run {
  grid-area: 9 / 4 / 10 / 5;
}

.hand {
  font-size: 60px;
}

.test {
  background-color: #ef3629;
}

.fullScreen {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.subtitle {
  z-index: 1;
  position: absolute;
  top: 30%;
  width: 100%;
  text-align: center;
  letter-spacing: 10px;
  font-family: "ComicSans";
  font-size: 40px;
  color: #ffffff;
  /* -webkit-text-stroke-width: 5px;
    -webkit-text-stroke-color: white; */
  pointer-events: none;
}

.subsubtitle {
  z-index: 1;
  position: absolute;
  top: 65%;
  width: 100%;
  text-align: center;
  letter-spacing: 10px;
  font-family: "ComicSans";
  font-size: 40px;
  color: #ffffff;
  pointer-events: none;
}

.leftScore {
  z-index: 1;
  position: absolute;
  top: 72%;
  right: 4%;
  width: 100%;
  text-align: center;
  letter-spacing: 10px;
  font-family: "ComicSans";
  font-size: 40px;
  color: #ffffff;
  pointer-events: none;
}

.rightScore {
  z-index: 1;
  position: absolute;
  top: 72%;
  left: 2%;
  width: 100%;
  text-align: center;
  letter-spacing: 10px;
  font-family: "ComicSans";
  font-size: 40px;
  color: #ffffff;
  pointer-events: none;
}

.bigEmoji {
  margin-top: -10px;
  font-size: 300px;
}
